.post-title-block{
  font-size: 30px;
  margin: 30px 20px 10px 10px;
}
.post-text-block{
  font-size: 30px;
  margin: 20px 10px 20px 10px;
}

.post-time-block{
  color: #959595;
  font-size: 12px;
  margin: 3px 10px 3px 10px;
}

.post-photo-block{
  font-size: 18px;
  margin: 3px 0 3px 0;
}

.post-photo-title{
  text-align: center;
}

.divider {
  height: 5px;
  width:100%;
  display:block; /* for use on default inline elements like span */
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}
